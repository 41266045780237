// extracted by mini-css-extract-plugin
export var borderWrapper = "Intro-module--borderWrapper--5447d";
export var content = "Intro-module--content--27882";
export var contentBodyDesktop = "Intro-module--contentBodyDesktop--35991";
export var contentBodyTablet = "Intro-module--contentBodyTablet--09f5d";
export var description = "Intro-module--description--c3420";
export var desktopTextAnimated = "Intro-module--desktopTextAnimated--dc6a4";
export var image = "Intro-module--image--cbe67";
export var imageWrapper = "Intro-module--imageWrapper--95b1a";
export var info = "Intro-module--info--e41e8";
export var label = "Intro-module--label--8122e";
export var labels = "Intro-module--labels--429ad";
export var mobileTextAnimated = "Intro-module--mobileTextAnimated--d5370";
export var phoneWrapper = "Intro-module--phoneWrapper--2c5c4";
export var ratio = "Intro-module--ratio--98e56";
export var root = "Intro-module--root--f1ca8";
export var subheader = "Intro-module--subheader--8db24";