import { FC, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import gsap from 'gsap';
import classNames from 'classnames';
// components
import Icon from 'src/components/Icon';

import { Props } from './Popup.interface';
import * as style from './Popup-styles.module.scss';

const modalsRootId = 'modals-root';

const Popup: FC<Props> = ({
  isOpen,
  onClose,
  triggerRef,
  children,
  isSaveInDOM = true,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);
  const root = useMemo(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const existRoot = document.getElementById(modalsRootId);

    if (existRoot) {
      return existRoot;
    }

    const newRoot = document.createElement('div');

    newRoot.id = modalsRootId;
    document.body.appendChild(newRoot);

    return newRoot;
  }, []);

  useEffect(() => {
    if (contentRef.current === null || triggerRef.current === null) {
      return;
    }

    const triggerRect = triggerRef.current.getBoundingClientRect();

    if (isOpen) {
      document.body.style.overflow = 'hidden';

      gsap.fromTo(
        contentRef.current,
        {
          // move from center of the trigger
          left: triggerRect.left + triggerRect.width / 2,
          top: triggerRect.top + triggerRect.height / 2,
          scale: 0,
        },
        {
          left: '50%',
          top: '50%',
          scale: 1,
          duration: 0.5,
          ease: 'power4.out',
        }
      );
      setLocalIsOpen(true);
    } else {
      document.body.style.overflow = 'unset';

      gsap.fromTo(
        contentRef.current,
        {
          left: '50%',
          top: '50%',
          scale: 1,
        },
        {
          left: triggerRect.left + triggerRect.width / 2,
          top: triggerRect.top + triggerRect.height / 2,
          scale: 0,
          duration: 0.3,
          ease: 'power4.out',
          onComplete: () => {
            setLocalIsOpen(false);
          },
        }
      );
    }
  }, [isOpen, triggerRef]);

  if (root && (isOpen || localIsOpen || isSaveInDOM)) {
    return ReactDOM.createPortal(
      <div
        className={classNames(style.root, {
          [style.hidden]: isSaveInDOM && !isOpen && !localIsOpen,
        })}
      >
        <div className={style.content} ref={contentRef}>
          {children}
          <button
            className={style.closeButton}
            onClick={onClose}
            aria-label='close-popup'
          >
            <Icon name='close' fill='#fff' />
          </button>
        </div>
        <div
          className={classNames(style.shadow, {
            [style.active]: isOpen,
          })}
          onClick={onClose}
        />
      </div>,
      root
    );
  }

  return null;
};

export default Popup;
