import { FC, useContext, useEffect, useRef } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Label from 'src/components/Label';
// animation
import { showBlock, showFromOpacity } from 'src/animations/block';
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
// context
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
import BannerContext from 'src/contexts/BannerContext';

import * as style from './Intro.module.scss';

type Props = {
  title: string;
  description: string;
  roles: { name: string }[];
  color: string;
  image: IGatsbyImageData;
};

const Intro: FC<Props> = ({ title, description, roles, color, image }) => {
  // context
  const { isPageLoaded } = useContext(SitePreloaderContext);
  const { isTopBannerVisible, topBannerRef } = useContext(BannerContext);
  // refs
  const desktopBorderRef = useRef<HTMLDivElement>(null);
  const desktopPhoneRef = useRef<HTMLDivElement>(null);
  const desktopLabelRef = useRef<HTMLDivElement>(null);
  const mobileBorderRef = useRef<HTMLDivElement>(null);
  const mobilePhoneRef = useRef<HTMLDivElement>(null);
  // memo
  const marginTop = isTopBannerVisible ? topBannerRef.current?.clientHeight : 0;
  // images
  const previewImage = getImage(image);

  useEffect(() => {
    if (
      desktopBorderRef.current !== null &&
      desktopPhoneRef.current !== null &&
      desktopLabelRef.current !== null &&
      isPageLoaded
    ) {
      const phoneAnimation = showFromOpacity(desktopPhoneRef.current).pause();
      const labelAnimation = showBlock(desktopLabelRef.current, {
        delay: 1,
      }).pause();
      const textAnimation = appearanceOfText(
        `.${style.desktopTextAnimated}`
      ).pause();

      const animation = showBlock(desktopBorderRef.current, {
        onComplete: () => {
          phoneAnimation.play();
          textAnimation.play();
          labelAnimation.play();
        },
      }).pause();

      onHTMLElementEnterOnWindow(desktopBorderRef.current, () => {
        animation.play();
      });
    }
  }, [isPageLoaded]);

  useEffect(() => {
    if (
      mobileBorderRef.current !== null &&
      mobilePhoneRef.current !== null &&
      isPageLoaded
    ) {
      const phoneAnimation = showFromOpacity(mobilePhoneRef.current).pause();
      const animation = showBlock(mobileBorderRef.current, {
        onComplete: () => {
          phoneAnimation.play();
        },
      }).pause();

      onHTMLElementEnterOnWindow(mobileBorderRef.current, () => {
        animation.play();
      });
    }
  }, [isPageLoaded]);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.mobileTextAnimated}`).pause();

    onHTMLElementEnterOnWindow(`.${style.mobileTextAnimated}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section className={style.root}>
      <div
        className={style.contentBodyDesktop}
        style={{
          borderColor: color,
          marginTop,
        }}
        ref={desktopBorderRef}
      >
        <div className={style.ratio} />
        <div className={style.content}>
          <div className={style.info}>
            <Typography className={style.desktopTextAnimated} variant='h1'>
              {title}
            </Typography>
            <Typography
              variant='body'
              className={classNames(
                style.description,
                style.desktopTextAnimated
              )}
            >
              {description}
            </Typography>
            <div className={style.labels} ref={desktopLabelRef}>
              {roles.map((role, i) => (
                <Label
                  key={i}
                  className={style.label}
                  color={color}
                  text={role.name}
                />
              ))}
            </div>
          </div>
        </div>
        <div ref={desktopPhoneRef}>
          {!!previewImage && (
            <GatsbyImage
              alt='preview'
              imgStyle={{ objectFit: 'contain' }}
              image={{ ...previewImage }}
              className={style.image}
            />
          )}
        </div>
      </div>
      <div className={style.contentBodyTablet}>
        <Typography
          className={style.mobileTextAnimated}
          variant='h1'
          style={{ marginTop }}
        >
          {title}
        </Typography>
        <Typography
          variant='body'
          className={classNames(style.subheader, style.mobileTextAnimated)}
        >
          Case studies
        </Typography>
        <div className={style.imageWrapper} ref={mobileBorderRef}>
          <div className={style.borderWrapper} style={{ borderColor: color }} />
          <div className={style.phoneWrapper} ref={mobilePhoneRef}>
            {!!previewImage && (
              <GatsbyImage
                alt='preview'
                image={previewImage}
                className={style.image}
                objectFit={'contain'}
              />
            )}
          </div>
        </div>
        <Typography
          variant='body'
          className={classNames(style.description, style.mobileTextAnimated)}
        >
          {description}
        </Typography>
      </div>
    </Section>
  );
};

export default Intro;
