import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// components
import Icon from 'src/components/Icon';
import Typography from 'src/components/Typography';
// context
import CursorContext from 'src/contexts/CursorContext';
// services
import { chunkArray } from 'src/services/helpers';
// animations
import { showFromOpacity } from 'src/animations/block';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import * as style from './TechStack.module.scss';
import type {
  Props,
  TechnologyKey,
  TechnologyList,
} from './TechStack.interface';

const _settings = {
  maxColumnsCount: 7,
  minBlockWidth: 130,
  windowWidth: 100,
};

const technologiesList: TechnologyList = {
  nodeJs: {
    name: 'Node.js',
    iconName: 'js',
  },
  ts: {
    name: 'TypeScript',
    iconName: 'ts',
  },
  react: {
    name: 'React',
    iconName: 'reactjs',
  },
  mongoDB: {
    name: 'Mongo DB',
    iconName: 'mongoDB',
  },
  postgres: {
    name: 'Postgres',
    iconName: 'postgres',
  },
  vue: {
    name: 'Vue',
    iconName: 'vue',
  },
  kubernetes: {
    name: 'Kubernetes',
    iconName: 'kubernetes',
  },
  amazon: {
    name: 'Amazon',
    iconName: 'amazon',
  },
  flutter: {
    name: 'Flutter',
    iconName: 'flutter',
  },
  terraform: {
    name: 'Terraform',
    iconName: 'terraform',
  },
  reactNative: {
    name: 'React Native',
    iconName: 'reactjs',
  },
  kafka: {
    name: 'Kafka',
    iconName: 'kafka',
  },
  js: {
    name: 'JavaScript',
    iconName: 'js2',
  },
  figma: {
    name: 'Figma',
    iconName: 'figma',
  },
  netlify: {
    name: 'Netlify',
    iconName: 'netlify',
  },
  gatsby: {
    name: 'Gatsby.js',
    iconName: 'gatsby',
  },
  googleCloud: {
    name: 'Google Cloud',
    iconName: 'googleCloud',
  },
  docker: {
    name: 'Docker',
    iconName: 'docker',
  },
  tailwind: {
    name: 'Tailwind',
    iconName: 'tailwind',
  },
  reactQuery: {
    name: 'React Query',
    iconName: 'reactQuery',
  },
};

const TechStack: FC<Props> = ({ techList }) => {
  // state
  const [columnsCount, setColumnsCount] = useState(_settings.maxColumnsCount);
  const [itemSize, setItemSize] = useState(0);
  // refs
  const rootRef = useRef<HTMLDivElement>(null);
  // context
  const { cursor } = useContext(CursorContext);
  // memo
  const columnsData = useMemo<TechnologyKey[][]>(() => {
    const techInColumn = Math.round(techList.length / columnsCount);

    if (techInColumn === 0) {
      return chunkArray(techList, 1);
    }

    return chunkArray(techList, techInColumn);
  }, [techList, columnsCount]);

  const updateColumnsCount = () => {
    if (rootRef.current === null) {
      return;
    }

    const recommendedColumnsCount = Math.round(
      rootRef.current.clientWidth / _settings.minBlockWidth
    );

    if (recommendedColumnsCount <= _settings.maxColumnsCount) {
      setColumnsCount(recommendedColumnsCount);
    }
  };

  useEffect(() => {
    if (rootRef.current === null) {
      return;
    }

    const animation = showFromOpacity(rootRef.current).pause();

    onHTMLElementEnterOnWindow(rootRef.current, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    updateColumnsCount();

    window.addEventListener('resize', updateColumnsCount);

    return () => {
      window.removeEventListener('resize', updateColumnsCount);
    };
  }, [updateColumnsCount]);

  useEffect(() => {
    if (rootRef.current === null) {
      return;
    }

    setItemSize(rootRef.current.clientWidth / columnsCount);
  }, [columnsCount]);

  const handleMouseEnter = useCallback(() => {
    cursor?.enter();
  }, [cursor]);

  const handleMouseLeave = useCallback(() => {
    cursor?.leave();
  }, [cursor]);

  return (
    <div className={style.root} ref={rootRef}>
      <div className={style.content}>
        {columnsData.map((column, columnIndex) => (
          <div
            className={style.column}
            style={{
              width: `${_settings.windowWidth / columnsCount}%`,
              marginTop: columnIndex % 2 === 0 ? itemSize / 2 : 0,
            }}
            key={columnIndex}
          >
            {column.map(
              (tech, techIndex) =>
                technologiesList.hasOwnProperty(tech) && (
                  <div
                    className={style.item}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    key={techIndex}
                  >
                    <Icon
                      className={style.icon}
                      name={technologiesList[tech].iconName}
                    />
                    <Typography variant='body' className={style.text}>
                      {technologiesList[tech].name}
                    </Typography>
                    <div className={style.ratio} />
                  </div>
                )
            )}
          </div>
        ))}
      </div>
      <div className={style.ratio} />
    </div>
  );
};

export default memo(TechStack);
