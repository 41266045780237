import { FC } from 'react';
// components
import Section from 'src/components/Section';
import ClientFeedbackCard from 'src/components/ClientFeedbackCard/ClientFeedbackCard';
// Hooks
import useClientFeedbackData from 'src/cms/data/home/useClientFeedback';
// types
import type { CMSVideo } from 'src/cms/data/types';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type Props = {
  projectFeedback: {
    clientName: string;
    subtitle: string;
    text: string;
    image: IGatsbyImageData;
    alt: string;
    video: CMSVideo | null;
  };
};

const Feedback: FC<Props> = ({ projectFeedback }) => {
  // hooks
  const clientFeedbackData = useClientFeedbackData();

  if (!projectFeedback) {
    return null;
  }

  return (
    <Section title={clientFeedbackData.title}>
      <ClientFeedbackCard isAnimation {...projectFeedback} />
    </Section>
  );
};

export default Feedback;
