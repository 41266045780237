import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
// hooks
import useWorkInNumbersData from 'src/cms/data/home/useWorkInNumbersData';
// animation
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showBlock } from 'src/animations/block';

import * as style from './WorkInNumbers.module.scss';

type Props = {
  items: {
    number: string;
    description: string;
  }[];
};

const WorkInNumbers: FC<Props> = ({ items }) => {
  // refs
  const blockItemRefs = useRef<HTMLDivElement[]>([]);
  // hooks
  const workInNumbersData = useWorkInNumbersData();

  useEffect(() => {
    const animation = appearanceOfText(`.${style.textAnimated}`).pause();

    onHTMLElementEnterOnWindow(`.${style.textAnimated}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    if (blockItemRefs.current.length > 0) {
      blockItemRefs.current.forEach((elem) => {
        const animation = showBlock(elem).pause();

        onHTMLElementEnterOnWindow(elem, () => {
          animation.play();
        });
      });
    }
  }, []);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Section title={workInNumbersData.title}>
      <div className={style.root}>
        {items.map((item, index) => (
          <div
            key={index}
            className={style.item}
            ref={(ref) => ref && blockItemRefs.current.push(ref)}
          >
            <div className={style.numberWrapper}>
              <Typography
                variant='body'
                className={classNames(style.number, style.textAnimated)}
              >
                {item.number}
              </Typography>
            </div>
            <Typography
              variant='body'
              className={classNames(style.description, style.textAnimated)}
            >
              {item.description}
            </Typography>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default WorkInNumbers;
