import { FC, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography/Typography';
import Link from 'src/components/Link';
// hooks
import useRoleData from 'src/cms/data/project/useRoleData';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showBlock } from 'src/animations/block';

import * as style from './Role.module.scss';

const query = graphql`
  {
    world: file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

type Props = {
  role: string;
  projects: string;
  service: string;
  specs: string;
  clientLocation: string;
  timeframe: string;
  links: {
    name: string;
    href: string;
  }[];
};

const Role: FC<Props> = ({
  role,
  projects,
  service,
  specs,
  clientLocation,
  timeframe,
  links,
}) => {
  // hooks
  const data = useStaticQuery(query);
  const roleData = useRoleData();

  // refs
  const blockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.text}`).pause();

    onHTMLElementEnterOnWindow(`.${style.text}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    if (blockRef.current === null) {
      return;
    }

    const animation = showBlock(blockRef.current).pause();
    const textAnimation = appearanceOfText(
      `.${style.rowTitle}, .${style.rowDescription}`
    ).pause();

    onHTMLElementEnterOnWindow(blockRef.current, () => {
      animation.play();
      textAnimation.play();
    });
  }, []);

  return (
    <Section className={style.root}>
      <div className={style.image}>
        <GatsbyImage
          alt='background'
          image={data.world.childImageSharp.gatsbyImageData}
        />
      </div>
      <Section
        title={roleData.title}
        withMargin={false}
        className={style.textInfo}
        titleClassName={style.title}
      >
        <Typography variant='body' className={style.text}>
          {role}
        </Typography>
      </Section>
      <Section withMargin={false} className={style.project} ref={blockRef}>
        <div className={style.line}>
          <Typography
            className={classNames(style.title, style.rowTitle)}
            variant='body'
          >
            Project
          </Typography>
          <Typography className={style.rowDescription} variant='body'>
            {projects}
          </Typography>
          <Typography
            className={classNames(style.title, style.rowTitle)}
            variant='body'
          >
            Service
          </Typography>
          <Typography className={style.rowDescription} variant='body'>
            {service}
          </Typography>
          <Typography
            className={classNames(style.title, style.rowTitle)}
            variant='body'
          >
            Specs
          </Typography>
          <Typography className={style.rowDescription} variant='body'>
            {specs}
          </Typography>
          <Typography
            className={classNames(style.title, style.rowTitle)}
            variant='body'
          >
            Client
          </Typography>
          <Typography className={style.rowDescription} variant='body'>
            {clientLocation}
          </Typography>
          <Typography
            className={classNames(style.title, style.rowTitle)}
            variant='body'
          >
            Links
          </Typography>
          <Typography variant='body' element='div'>
            {links?.map((link, index) => (
              <Link
                key={index}
                text={link.name}
                href={link.href}
                target={'_blank'}
              />
            ))}
          </Typography>
          <Typography
            className={classNames(style.title, style.rowTitle)}
            variant='body'
          >
            Timeframe
          </Typography>
          <Typography variant='body'>{timeframe}</Typography>
        </div>
      </Section>
    </Section>
  );
};

export default Role;
