import { FC, useEffect, useMemo, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import WebsitePage from 'src/components/WebsitePage';

import * as style from './ProductBlock.module.scss';
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showBlock } from 'src/animations/block';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

const query = graphql`
  {
    dashboard: file(relativePath: { eq: "dashboard.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

type Props = {
  title: string;
  description: string;
  isHeader: boolean;
  image: IGatsbyImageData;
  reversed?: boolean;
};

const scale = 100;

const ProductBlock: FC<Props> = ({
  title,
  description,
  isHeader,
  image,
  reversed = false,
}) => {
  // hooks
  const data = useStaticQuery(query);
  // refs
  const photoRef = useRef<HTMLDivElement>(null);
  // memo
  const ratio = useMemo(
    () => (1 / data.dashboard.childImageSharp.gatsbyImageData.width) * scale,
    [data]
  );
  // images
  const productImage = getImage(image);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    if (photoRef.current === null) {
      return;
    }

    const animation = showBlock(photoRef.current).pause();

    onHTMLElementEnterOnWindow(photoRef.current, () => {
      animation.play();
    });
  }, []);

  return (
    <Section className={classNames(style.root, { [style.reversed]: reversed })}>
      <Section
        title={title}
        className={style.textInfo}
        titleClassName={classNames(style.title, { [style.reversed]: reversed })}
        withMargin={false}
      >
        <Typography className={style.animatedText} variant='body'>
          {description}
        </Typography>
      </Section>
      <div className={style.imageBlock} ref={photoRef}>
        {isHeader ? (
          <WebsitePage image={image} ratioSize={ratio} />
        ) : (
          !!productImage && (
            <GatsbyImage alt='product-image' image={productImage} />
          )
        )}
      </div>
    </Section>
  );
};

export default ProductBlock;
