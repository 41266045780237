import { FC, memo, useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import * as style from './WebsitePage.module.scss';
import type { Props } from './WebsitePage.interface';

const percents = 100;

const WebsitePage: FC<Props> = ({
  image,
  isHeader = true,
  ratioSize = 0,
  className,
}) => {
  const [pageHeight, setPageHeight] = useState<number>(0);
  const rootRef = useRef<HTMLDivElement>(null);

  const websiteImage = getImage(image);

  useEffect(() => {
    if (rootRef.current !== null) {
      const height = rootRef.current.clientWidth * (ratioSize / percents);

      if (pageHeight !== height) {
        setPageHeight(height);
      }
    }
  }, [pageHeight, ratioSize]);

  return (
    <div className={style.root}>
      {isHeader && (
        <div className={style.header}>
          <div className={style.buttons}>
            <div className={style.button} />
            <div className={style.button} />
            <div className={style.button} />
          </div>
        </div>
      )}
      <div className={classNames(style.content, className)} ref={rootRef}>
        <div className={style.imageWrapper}>
          {!!websiteImage && (
            <GatsbyImage
              alt='website_img'
              className={style.image}
              image={websiteImage}
            />
          )}
        </div>
        {typeof ratioSize === 'number' && (
          <div className={style.ratio} style={{ height: pageHeight }} />
        )}
      </div>
    </div>
  );
};

export default memo(WebsitePage);
