import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';

import * as style from './DraggableScroll.module.scss';
import type { Props } from './DraggableScroll.interface';

const scrollSpeed = 2;
const percents = 100;
const scrollbarWidth = 160;
const thumbWidth = 40;

const DraggableScroll: FC<Props> = ({ withScroll = true, children }) => {
  // state
  const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);
  // refs
  const isDragActiveRef = useRef<boolean>(false);
  const sliderPositionRef = useRef({ top: 0, left: 0, x: 0, y: 0 });
  const thumbPositionRef = useRef({ left: 0 });
  // slider refs
  const sliderRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  // scrollbar refs
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (typeof document === 'undefined' || trackRef.current === null) {
      return;
    }

    if (trackRef.current.clientWidth > document.body.clientWidth) {
      setIsScrollbarVisible(true);
    }
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (sliderRef.current !== null) {
      sliderPositionRef.current = {
        left: sliderRef.current.scrollLeft,
        top: sliderRef.current.scrollTop,
        x: e.clientX || e.changedTouches[0].clientX,
        y: e.clientY || e.changedTouches[0].clientX,
      };
      if (thumbRef.current !== null) {
        const left = window.getComputedStyle(thumbRef.current).left;

        thumbPositionRef.current = {
          left: +left.slice(0, left.length - 2),
        };
      }

      isDragActiveRef.current = true;
    }
  }, []);

  const handleDraggableDisable = useCallback(() => {
    isDragActiveRef.current = false;
  }, []);

  const handleSliderMouseMove = useCallback((e) => {
    if (!isDragActiveRef.current) {
      return;
    }

    if (sliderRef.current === null) {
      return;
    }

    const differenceX =
      (e.changedTouches?.[0].clientX || e.clientX) -
      sliderPositionRef.current.x;

    // move track
    sliderRef.current.scrollLeft =
      sliderPositionRef.current.left - differenceX * scrollSpeed;

    if (trackRef.current === null || scrollbarRef.current === null) {
      return;
    }

    if (thumbRef.current === null) {
      return;
    }

    // move thumb
    const relationTrackToScrollbar =
      (sliderRef.current.scrollLeft * percents) /
      (trackRef.current.clientWidth - sliderRef.current.clientWidth) /
      percents;
    const thumbLeft =
      (scrollbarRef.current.clientWidth - thumbWidth) *
      relationTrackToScrollbar;

    thumbRef.current.style.left = `${thumbLeft}px`;
  }, []);

  const handleScrollbarMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!isDragActiveRef.current) {
        return;
      }

      if (
        trackRef.current === null ||
        sliderRef.current === null ||
        scrollbarRef.current === null ||
        thumbRef.current === null
      ) {
        return;
      }

      // move thumb
      const mousePosition =
        e.clientX - scrollbarRef.current.getBoundingClientRect().left;

      if (
        mousePosition < thumbWidth / 2 ||
        mousePosition > scrollbarWidth - thumbWidth / 2
      ) {
        return;
      }

      thumbRef.current.style.left = `${mousePosition - thumbWidth / 2}px`;

      // move track
      const thumbLeft = window.getComputedStyle(thumbRef.current).left;
      const relationTrackToScrollbar =
        (+thumbLeft.slice(0, thumbLeft.length - 2) * percents) /
        (scrollbarWidth - thumbWidth) /
        percents;

      sliderRef.current.scrollLeft =
        (trackRef.current.clientWidth - sliderRef.current.clientWidth) *
        relationTrackToScrollbar;
    },
    []
  );

  return (
    <div className={style.root}>
      <div
        className={classNames(style.slider, {
          [style.withoutScroll]: !withScroll,
        })}
        ref={sliderRef}
      >
        <div
          ref={trackRef}
          className={style.track}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
          onMouseUp={handleDraggableDisable}
          onTouchEnd={handleDraggableDisable}
          onMouseLeave={handleDraggableDisable}
          onMouseMove={handleSliderMouseMove}
          onTouchMove={handleSliderMouseMove}
        >
          {children}
        </div>
      </div>
      {withScroll && isScrollbarVisible && (
        <div
          className={style.scrollbar}
          ref={scrollbarRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleDraggableDisable}
          onMouseLeave={handleDraggableDisable}
          onMouseMove={handleScrollbarMouseMove}
        >
          <button className={style.thumb} ref={thumbRef} />
        </div>
      )}
    </div>
  );
};

export default DraggableScroll;
