// extracted by mini-css-extract-plugin
export var hoveredImage = "ProjectPreviewShort-module--hoveredImage--dbe47";
export var image = "ProjectPreviewShort-module--image--af9c3";
export var imageWrapper = "ProjectPreviewShort-module--imageWrapper--113f2";
export var leftPositioningImage = "ProjectPreviewShort-module--leftPositioningImage--373f7";
export var leftPositioningText = "ProjectPreviewShort-module--leftPositioningText--4dc7b";
export var rightPositioningImage = "ProjectPreviewShort-module--rightPositioningImage--e50ec";
export var rightPositioningText = "ProjectPreviewShort-module--rightPositioningText--343e5";
export var root = "ProjectPreviewShort-module--root--e41ee";
export var text = "ProjectPreviewShort-module--text--73b52";
export var textWrapper = "ProjectPreviewShort-module--textWrapper--3f43e";