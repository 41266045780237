import { FC, useMemo } from 'react';
// components
import Section from 'src/components/Section';
import ProjectPreviewShort from 'src/components/ProjectPreviewShort';
import Link from 'src/components/Link';
// hooks
import usePortfolioData from 'src/cms/data/home/usePortfolioData';
import useMoreWorkData from 'src/cms/data/project/useMoreWorkData';

import * as style from './MoreWork.module.scss';

type Props = {
  title: string;
};

const minProjectLength = 3;

const MoreWork: FC<Props> = ({ title }) => {
  // hooks
  const { list } = usePortfolioData();
  const moreWorkData = useMoreWorkData();

  if (list.length < minProjectLength) {
    return null;
  }

  const [prevPortfolio, nextPortfolio] = useMemo(() => {
    const index = list.findIndex((item) => item.title === title);

    if (index === -1) {
      return [list[0], list[1]];
    }

    return [
      list[index - 1 > 0 ? index - 1 : list.length - 1],
      list[index + 1 >= list.length ? 0 : index + 1],
    ];
  }, [list, title]);

  return (
    <Section title={moreWorkData.title}>
      <div className={style.root}>
        <Link
          href={
            prevPortfolio.isProjectReady
              ? `/projects/${prevPortfolio.id}`
              : prevPortfolio.link
          }
          className={style.project}
          target={prevPortfolio.isProjectReady ? '_self' : '_blank'}
          rel='noreferrer'
          variant={prevPortfolio.isProjectReady ? 'internal' : 'external'}
        >
          <ProjectPreviewShort imagePosition='left' {...prevPortfolio} />
        </Link>
        <Link
          href={
            nextPortfolio.isProjectReady
              ? `/projects/${nextPortfolio.id}`
              : nextPortfolio.link
          }
          className={style.project}
          target={nextPortfolio.isProjectReady ? '_self' : '_blank'}
          rel='noreferrer'
          variant={nextPortfolio.isProjectReady ? 'internal' : 'external'}
        >
          <ProjectPreviewShort imagePosition='right' {...nextPortfolio} />
        </Link>
      </div>
    </Section>
  );
};

export default MoreWork;
