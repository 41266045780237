import { FC } from 'react';
// components
import Section from 'src/components/Section';
import TechStackComponent from 'src/components/TechStack';
// hooks
import useTechStackData from 'src/cms/data/project/useTechStackData';
// types
import type { TechnologyKey } from 'src/components/TechStack/TechStack.interface';

import * as style from './TechStack.module.scss';

type Props = {
  technologies: TechnologyKey[];
};

const TechStack: FC<Props> = ({ technologies }) => {
  // hooks
  const techStackData = useTechStackData();

  if (!technologies || technologies.length === 0) {
    return null;
  }

  return (
    <Section
      title={techStackData.title}
      rootClassName={style.root}
      titleClassName={style.title}
    >
      <TechStackComponent techList={technologies} />
    </Section>
  );
};

export default TechStack;
