import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
// components
import Popup from 'src/components/Popup';

import { Props, VideoScale } from './VideoPopup.interface';
import * as style from './VideoPopup.module.scss';

const VideoPopup: FC<Props> = ({ isOpen, src, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoScale, setVideoScale] = useState<VideoScale>({
    width: 0,
    height: 0,
  });

  const playVideo = () => {
    if (videoRef.current === null) {
      return;
    }

    videoRef.current.play().catch(() => {
      if (videoRef.current !== null) {
        videoRef.current.muted = true;
        videoRef.current.play().catch(() => console.warn("can't play video"));
      }
    });
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (isOpen) {
      playVideo();
    } else {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [isOpen]);

  const handleLoadedMetadata = (e: SyntheticEvent<HTMLVideoElement>) => {
    setVideoScale({
      width: (e.target as HTMLVideoElement).videoWidth,
      height: (e.target as HTMLVideoElement).videoHeight,
    });
  };

  return (
    <Popup isOpen={isOpen} {...props}>
      <div className={style.root}>
        <video
          className={classNames(style.content, {
            [style.fullWidth]: videoScale.width >= videoScale.height,
            [style.fullHeight]: videoScale.width < videoScale.height,
          })}
          ref={videoRef}
          onLoadedMetadata={handleLoadedMetadata}
          preload='auto'
          controls
        >
          <source src={src} type='video/mp4' />
        </video>
      </div>
    </Popup>
  );
};

export default VideoPopup;
