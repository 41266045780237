import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
// components
import Typography from 'src/components/Typography/Typography';
// context
import CursorContext from 'src/contexts/CursorContext';
// animation
import { showBlock, showFromOpacity } from 'src/animations/block';
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';

import { Props } from './ProjectPreviewShort.interface';
import * as style from './ProjectPreviewShort.module.scss';

const ProjectPreviewShort: FC<Props> = ({
  imagePosition,
  frameColor,
  title,
  image,
}) => {
  // state
  const [isHover, setIsHover] = useState(false);
  // context
  const { cursor } = useContext(CursorContext);
  // refs
  const borderRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (borderRef.current !== null && phoneRef.current !== null) {
      const phoneAnimation = showFromOpacity(phoneRef.current).pause();
      const textAnimation = appearanceOfText(`.${style.text}`).pause();
      const animation = showBlock(borderRef.current, {
        onComplete: () => {
          phoneAnimation.play();
          textAnimation.play();
        },
      }).pause();

      onHTMLElementEnterOnWindow(borderRef.current, () => {
        animation.play();
      });
    }
  }, []);

  const handleMouseEnter = useCallback(() => {
    cursor?.enter();
    setIsHover(true);
  }, [cursor]);

  const handleMouseLeave = useCallback(() => {
    cursor?.leave();
    setIsHover(false);
  }, [cursor]);

  return (
    <div
      className={style.root}
      ref={borderRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={classNames(style.textWrapper, {
          [style.leftPositioningText]: imagePosition === 'right',
          [style.rightPositioningText]: imagePosition === 'left',
        })}
        style={{ borderColor: frameColor }}
      >
        <Typography
          className={style.text}
          variant='body'
          style={{ color: isHover ? frameColor : '#fff' }}
        >
          Corporate website
        </Typography>
        <Typography
          className={style.text}
          variant='h3'
          style={{ color: isHover ? frameColor : '#fff' }}
        >
          {title}
        </Typography>
      </div>
      {image && (
        <div
          className={classNames(style.imageWrapper, {
            [style.rightPositioningImage]: imagePosition === 'right',
            [style.leftPositioningImage]: imagePosition === 'left',
          })}
          ref={phoneRef}
        >
          <GatsbyImage
            alt='project_preview'
            image={image}
            className={classNames(style.image, {
              [style.hoveredImage]: isHover,
            })}
          />
        </div>
      )}
      <div style={{ backgroundColor: frameColor }} />
    </div>
  );
};

export default ProjectPreviewShort;
