import { FC, useEffect, useRef } from 'react';
// components
import Section from 'src/components/Section';
import Icon from 'src/components/Icon';
import Typography from 'src/components/Typography';
// hooks
import useFeaturesData from 'src/cms/data/project/useFeaturesData';
// animation
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showFromOpacity } from 'src/animations/block';

import * as style from './Features.module.scss';

type Props = {
  features: {
    description: string;
  }[];
};

const Features: FC<Props> = ({ features }) => {
  // refs
  const featuresRef = useRef<HTMLDivElement>(null);
  // hooks
  const featuresData = useFeaturesData();

  useEffect(() => {
    if (featuresRef.current !== null) {
      const animation = appearanceOfText(`.${style.text}`).pause();
      const checkboxAnimation = showFromOpacity(`.${style.icon}`, {
        delay: 1,
        duration: 3,
      }).pause();

      onHTMLElementEnterOnWindow(featuresRef.current, () => {
        animation.play();
        checkboxAnimation.play();
      });
    }
  }, []);

  if (!features || features.length === 0) {
    return null;
  }

  return (
    <Section title={featuresData.title}>
      <div className={style.features} ref={featuresRef}>
        {features.map((feature, index) => (
          <div key={index} className={style.feature}>
            <Icon name='checkbox' className={style.icon} />
            <Typography className={style.text} variant='body'>
              {feature.description}
            </Typography>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Features;
