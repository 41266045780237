import { FC, memo, useContext } from 'react';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
// context
import CursorContext from 'src/contexts/CursorContext';

import { Props } from './Lable.interface';
import * as style from './Label.module.scss';

const Label: FC<Props> = ({ color = '#FF4747', text, className }) => {
  const { cursor } = useContext(CursorContext);

  const handleMouseEnter = () => {
    cursor?.enter();
  };

  const handleMouseLeave = () => {
    cursor?.leave();
  };

  return (
    <div
      className={classNames(style.root, className)}
      style={{ backgroundColor: color }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography variant='body'>{text}</Typography>
    </div>
  );
};

export default memo(Label);
