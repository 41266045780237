import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
// components
import Typography from 'src/components/Typography';
import Icon from 'src/components/Icon';
import Link from 'src/components/Link/Link';
// containers
import VideoPopup from 'src/containers/VideoPopup';
// animations
import { appearanceOfText } from 'src/animations/text';
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showBlock, showFromOpacity } from 'src/animations/block';
// context
import CursorContext from 'src/contexts/CursorContext';

import { Props } from './ClientFeedbackCard.interface';
import * as style from './ClientFeedbackCard.module.scss';

const ClientFeedbackCard: FC<Props> = ({
  image,
  alt,
  subtitle,
  websiteLink,
  websiteText,
  clientName,
  text,
  video,
  isAnimation,
  onMouseEnter,
  onMouseLeave,
}) => {
  // state
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  // context
  const { cursor } = useContext(CursorContext);
  // refs
  const photoRef = useRef<HTMLDivElement>(null);
  const infoBlockRef = useRef<HTMLDivElement>(null);
  const playButtonRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef<HTMLDivElement>(null);
  // images
  const feedbackImage = getImage(image);

  useEffect(() => {
    const animation = appearanceOfText(`.${style.animatedText}`).pause();

    onHTMLElementEnterOnWindow(`.${style.animatedText}`, () => {
      animation.play();
    });
  }, []);

  useEffect(() => {
    if (linkRef.current !== null) {
      const animation = showFromOpacity(linkRef.current, {
        duration: 3,
      }).pause();

      onHTMLElementEnterOnWindow(linkRef.current, () => {
        animation.play();
      });
    }
  }, []);

  useEffect(() => {
    if (photoRef.current !== null && isAnimation) {
      const animation = showBlock(photoRef.current).pause();

      onHTMLElementEnterOnWindow(photoRef.current, () => {
        animation.play();
      });
    }
  }, [isAnimation]);

  useEffect(() => {
    if (infoBlockRef.current !== null && isAnimation) {
      const animation = showBlock(infoBlockRef.current).pause();

      onHTMLElementEnterOnWindow(infoBlockRef.current, () => {
        animation.play();
      });
    }
  }, [isAnimation]);

  const handleMouseEnter = () => {
    cursor?.enter();
  };

  const handleMouseLeave = () => {
    cursor?.leave();
  };

  const handleVideoPlay = useCallback(() => {
    setIsVideoOpen(true);
  }, []);

  const handleVideoClose = useCallback(() => {
    setIsVideoOpen(false);
  }, []);

  return (
    <>
      <div
        className={style.root}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={style.photoWrapper} ref={photoRef}>
          {!!feedbackImage && (
            <GatsbyImage
              image={feedbackImage}
              className={style.photo}
              alt={alt}
            />
          )}
          <div className={style.photoRatio} />
          {video && (
            <button
              className={style.playButton}
              ref={playButtonRef}
              onClick={handleVideoPlay}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Icon name='play' fill='inherit' width={100} />
            </button>
          )}
        </div>
        <div className={style.infoCard} ref={infoBlockRef}>
          <Typography
            className={classNames(style.title, style.animatedText)}
            variant='h4'
          >
            {clientName}
          </Typography>
          <Typography
            className={classNames(style.subTitle, style.animatedText)}
            variant='body'
          >
            {subtitle}
          </Typography>
          <div ref={linkRef}>
            <Link
              text={websiteText}
              className={style.link}
              href={websiteLink}
              iconRightName='arrowRight'
              target='_blank'
              rel='noreferrer'
            />
          </div>
          <Typography className={style.animatedText} variant='h3'>
            {text}
          </Typography>
        </div>
      </div>
      {video && (
        <VideoPopup
          src={video.publicURL}
          isOpen={isVideoOpen}
          onClose={handleVideoClose}
          triggerRef={playButtonRef}
        />
      )}
    </>
  );
};

export default memo(ClientFeedbackCard);
