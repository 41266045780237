import { FC, useEffect } from 'react';
// components
import Section from 'src/components/Section/Section';
import Link from 'src/components/Link';
// hooks
import useLinksData from 'src/cms/data/project/useLinksData';
// animation
import { onHTMLElementEnterOnWindow } from 'src/animations/scroll';
import { showFromOpacity } from 'src/animations/block';

import * as style from './Links.module.scss';

type Props = {
  links: {
    appStore: string | undefined;
    googlePlay: string | undefined;
    website: string | undefined;
  };
};

const Links: FC<Props> = ({ links }) => {
  // hooks
  const linksData = useLinksData();

  if (
    !links ||
    Object.values(links).filter((value) => value !== undefined && value !== '')
      .length === 0
  ) {
    return null;
  }

  useEffect(() => {
    const animation = showFromOpacity(`.${style.link}`).pause();

    onHTMLElementEnterOnWindow(`.${style.link}`, () => {
      animation.play();
    });
  }, []);

  return (
    <Section title={linksData.title}>
      <div className={style.root}>
        {links.appStore && (
          <div className={style.link}>
            <Link
              href={links.appStore}
              text='App Store'
              iconRightName='arrowRight'
              iconLeftName='appleStore'
            />
          </div>
        )}
        {links.googlePlay && (
          <div className={style.link}>
            <Link
              href={links.googlePlay}
              text='Google Play'
              iconRightName='arrowRight'
              iconLeftName='googlePlay'
            />
          </div>
        )}
        {links.website && (
          <div className={style.link}>
            <Link
              href={links.website}
              text='Official website'
              iconRightName='arrowRight'
              iconLeftName='website'
            />
          </div>
        )}
      </div>
    </Section>
  );
};

export default Links;
