// extracted by mini-css-extract-plugin
export var animatedText = "ClientFeedbackCard-module--animatedText--8c4a7";
export var description = "ClientFeedbackCard-module--description--63789";
export var infoCard = "ClientFeedbackCard-module--infoCard--a21e3";
export var link = "ClientFeedbackCard-module--link--f8894";
export var photo = "ClientFeedbackCard-module--photo--eabe8";
export var photoRatio = "ClientFeedbackCard-module--photoRatio--8b76b";
export var photoWrapper = "ClientFeedbackCard-module--photoWrapper--d5dd4";
export var playButton = "ClientFeedbackCard-module--playButton--96a5d";
export var root = "ClientFeedbackCard-module--root--6306b";
export var subTitle = "ClientFeedbackCard-module--subTitle--71840";
export var title = "ClientFeedbackCard-module--title--7ecad";